import { InputSourceType, InputValueType } from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { useWorkspaceInputsFieldArrayContext } from "../../contexts/WorkspaceInputsContext";
import useSetVisibleAsset from "../useSetVisibleAsset";

function useAddNewSheet() {
  const { append } = useWorkspaceInputsFieldArrayContext();
  const setVisibleAsset = useSetVisibleAsset();

  return (selectedToolId = "") => {
    const id = uuidv4();
    append({
      name: `New sheet`,
      label: `New sheet`,
      source: InputSourceType.Manual,
      value: {
        sheetData: [],
        selectedToolId
      },
      valueType: InputValueType.Sheet,
      id
    });
    setVisibleAsset(id);

    return id;
  };
}

export default useAddNewSheet;
