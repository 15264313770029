import {
  BlockNode,
  CopyableField,
  type StructuredBlockData,
  type CustomToolInputField,
  type CustomToolOutputField,
  type TBlock,
  type ToolOutputOption,
  type UniqueNameOutput,
  type JSONSchemaItem,
  type SerpBlockData,
  OutputType
} from "@toolflow/shared";
import { PromptTypeToOutputFieldMap } from "../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";
import { isArray, isBoolean } from "lodash";

function filterToolOutputOptions(toolOutputOptions: ToolOutputOption[]) {
  return toolOutputOptions.filter(
    (v, i, a) =>
      a.findIndex((t) => t.value === v.value && t.type === v.type) === i
  );
}

export function getOutputsFromJsonSchema(
  schema: JSONSchemaItem | JSONSchemaItem[],
  prepend?: string
) {
  const paths: Path[] = [];

  if (isArray(schema)) {
    schema.forEach((item) => {
      paths.push(...getOutputsFromJsonSchema(item, prepend));
    });
  } else if (schema.type === "object") {
    schema.items.forEach((item) => {
      paths.push(
        ...getOutputsFromJsonSchema(
          item,
          prepend ? `${prepend}.${schema.fieldLabel}` : schema.fieldLabel
        ).map((mapItem) => ({
          ...mapItem,
          path: mapItem.path
        }))
      );
    });
  } else {
    paths.push({
      path: prepend ? `${prepend}.${schema.fieldLabel}` : schema.fieldLabel,
      type: schema.type
    });
  }
  return paths;
}

interface Path {
  path: string;
  type: JSONSchemaItem["type"];
}

export const getUniqueOutputNames = (
  customToolInputFields: CustomToolInputField[],
  nodes: TBlock[]
): UniqueNameOutput => {
  // Extract names from customToolInputFields
  const inputFieldNames = customToolInputFields
    .filter((field) => field.name && field.name.trim() !== "")
    .map((field) => {
      return {
        value: field.name,
        type: CopyableField.LargeText
      } as ToolOutputOption;
    });

  const blockFieldNames: ToolOutputOption[] = [];

  nodes.forEach((node) => {
    if (
      node.type === BlockNode.PromptBlockNode ||
      node.type === BlockNode.ConstantBlockNode ||
      node.type === BlockNode.DeepgramBlockNode ||
      node.type === BlockNode.ScraperBlockNode ||
      node.type === BlockNode.PerplexityBlockNode
    ) {
      blockFieldNames.push({
        value: node.data.label,
        type: PromptTypeToOutputFieldMap[
          node.data.type as keyof typeof PromptTypeToOutputFieldMap // Todo: Could be better
        ]
      });
    } else if (node.type === BlockNode.SerpBlockNode) {
      const data = node.data as SerpBlockData;
      const outputs = Object.entries(data.settings.allowedOutputs).reduce(
        (acc, [key, value]) => {
          if (isBoolean(value) && value) {
            acc.push({
              outputType: OutputType.JsonObject,
              nodeName: data.label,
              fieldKey: key,
              value: `${data.label}.${key}`,
              label: `${data.label}.${key}`,
              type: CopyableField.LargeText
            });
          }
          return acc;
        },
        [] as ToolOutputOption[]
      );

      blockFieldNames.push({
        value: node.data.label,
        type: PromptTypeToOutputFieldMap[
          node.data.type as keyof typeof PromptTypeToOutputFieldMap // Todo: Could be better
        ],
        outputs
      });
    } else if (node.type === BlockNode.StructuredBlockNode) {
      const data = node.data as StructuredBlockData;

      const outputs = getOutputsFromJsonSchema(data.settings.schema);

      blockFieldNames.push({
        value: data.label,
        type: PromptTypeToOutputFieldMap[data.type],
        outputs: outputs.map((item) => ({
          outputType: OutputType.JsonString,
          nodeName: data.label,
          fieldKey: item.path,
          value: `${data.label}.${item.path}`,
          label: `${data.label}.${item.path}`,
          type: CopyableField.LargeText
        }))
      });
    } else if (node.type === BlockNode.ToolWithinToolBlockNode) {
      // i think there is a bug where
      node.data.tool?.main.toolOutputFields.forEach(
        (field: CustomToolOutputField) => {
          blockFieldNames.push({
            value: `${node.data.label} - ${field.name}`,
            type: field.type
          });
        }
      );
    }
  });

  const filteredInputs = filterToolOutputOptions(inputFieldNames);
  const filteredBlocks = filterToolOutputOptions(blockFieldNames);

  return { inputs: filteredInputs, blocks: filteredBlocks };
};
