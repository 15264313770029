import {
  BlockNode,
  type PromptBlockProps,
  type PromptBlockData,
  type UserInputDictType
} from "@toolflow/shared";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../../../common/TestToolRun";
import TestBlockOutput from "../../../components/TestBlockOutput";
import useTest from "../../../hooks/useTest";
import TestFields from "../../testFields/TestFields";
import { PromptTypeToOutputFieldMap } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";

const TestPromptBase = ({
  filledOutPrompt,
  data,
  id,
  userInput,
  fields,
  setUserInput,
  fieldLabel
}: Omit<PromptBlockProps, "selected"> & {
  filledOutPrompt: string;
  userInput: UserInputDictType;
  fields: string[];
  setUserInput: React.Dispatch<React.SetStateAction<UserInputDictType>>;
  fieldLabel: string;
}) => {
  const { type } = data;
  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<PromptBlockData>({
      userInput,
      data,
      id,
      fields,
      blockType: BlockNode.PromptBlockNode
    });

  return (
    <DrawerBottom
      mainContent={
        <>
          <TestFields
            uI={userInput}
            setUI={setUserInput}
            filledOutPrompt={filledOutPrompt}
            label={fieldLabel}
          />
          <TestBlockOutput
            output={output}
            label={label}
            type={PromptTypeToOutputFieldMap[type]}
          />
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
};

export default TestPromptBase;
