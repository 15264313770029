import {
  type TextGenerationProps,
  FieldType,
  type FieldConfig,
  AnthropicModel,
  BlockType
} from "@toolflow/shared";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import { getTextGenerationTechOptions } from "../../../../helpers/priceDict";
import useUpdateField from "../../../../useUpdateField";
import { useCallback } from "react";

function PromptBlockInnerv2({
  data,
  id
}: Omit<TextGenerationProps, "selected">) {
  const updateField = useUpdateField(id);

  const updateBlockType = useCallback(
    (value: string) => {
      if (Object.values(AnthropicModel).includes(value as AnthropicModel)) {
        updateField(BlockType.Anthropic, "type");
      } else {
        updateField(BlockType.ChatGPT, "type");
      }
    },
    [updateField]
  );

  const configs: FieldConfig = {
    fields: [
      {
        type: FieldType.NewSelectInput,
        label: "Model",
        subtitle: "Select text generation technology",
        config: {
          fieldKey: `settings.llmModel`,
          options: getTextGenerationTechOptions(),
          callback: updateBlockType
        }
      },
      {
        type: FieldType.PromptInputV2,
        label: "LLM Prompt",
        subtitle: "Set and preview AI instructions for response generation.",
        config: {
          fieldKey: "settings.prompt",
          placeholder: "Enter prompt",
          autofocus: true
        }
      },
      {
        type: FieldType.Slider,
        label: "Temperature",
        subtitle:
          "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
        config: {
          fieldKey: `settings.temperature`
        }
      }
    ]
  };

  return (
    <>
      <FieldsByType id={id} configs={configs} settings={data} />
    </>
  );
}

export default PromptBlockInnerv2;
