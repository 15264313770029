import useWorkspaceInputs from "../../../../../features/pages/workstation/hooks/useWorkspaceInputs";
import { useMemo } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import formatInputsForAutocomplete from "../../../autocomplete/functions/formatInputsForAutocomplete";
import type { InputNodeTypes } from "@toolflow/shared";

export const useFilteredInputsForAutocompleteBase = ({
  inputType = "node",
  id
}: {
  inputType?: InputNodeTypes;
  id?: string;
}) => {
  const inputs = useWorkspaceInputs();
  return useMemo(() => {
    return formatInputsForAutocomplete(inputs, inputType, id);
  }, [inputs]);
};

const useFilteredInputsForAutocomplete = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { inputType, id } = muiTipTapProps;
  return useFilteredInputsForAutocompleteBase({
    inputType,
    id
  });
};

export default useFilteredInputsForAutocomplete;
