import {
  BlockNode,
  OutputType,
  type StructuredBlockData,
  type ValidatedInput
} from "@toolflow/shared";
import { useMemo } from "react";
import { BLOCK_OUTPUT } from "../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import { getOutputsFromJsonSchema } from "./helpers/uniqueNameHelpers";

const useToolInputFieldsAndAvailableFields = (id: string) => {
  const { state } = useToolbuilderContext();
  const { toolInputFields, availableFields, blocks, toolOutputOptions } =
    state.currentState;

  const fieldNames: ValidatedInput[] = toolInputFields.map(
    ({ id: newId, ...rest }) => rest
  );

  // toolInputFields.forEach((tag: ValidatedInput) => {
  //   fieldNames.push(tag); // Always add the tag name

  //   // If the tag type is "fileUpload" and there are options,
  //   // split them by "," and add them to the array
  //   if (tag.type === "csvFileUpload") {
  //     const additionalFields = tag?.options || [];
  //     fieldNames = [...fieldNames, ...additionalFields];
  //   }
  // });

  const availableFieldsById: ValidatedInput[] = useMemo(() => {
    // we don't want logicBlockNodes to show up in prompts
    const logicBlockNodeLabels = blocks
      .filter((block) => block.type === "logicBlockNode")
      .map((block) => block.data.label);

    const fields = availableFields[id] || [];

    return fields.reduce((acc, f) => {
      if (logicBlockNodeLabels.includes(f)) return acc;

      acc.push({ name: f, type: BLOCK_OUTPUT });

      const block = blocks.find((b) => b.data.label === f);
      if (block?.type === BlockNode.StructuredBlockNode) {
        const data = block.data as StructuredBlockData;
        const availableJsonOutputs = getOutputsFromJsonSchema(
          data.settings.schema
        );
        availableJsonOutputs.forEach((jo) => {
          acc.push({
            name: f,
            type: BLOCK_OUTPUT,
            config: {
              type: OutputType.JsonString,
              label: `${f}.${jo.path}`,
              nestedOutputKey: jo.path
            }
          });
        });
      }

      return acc;
    }, [] as ValidatedInput[]);
  }, [id, availableFields, toolOutputOptions]);

  return fieldNames.concat(availableFieldsById);
};

export default useToolInputFieldsAndAvailableFields;
