import { ChipNodeComponents } from "../ChipNodeComponents";
import { ChipNodeHtmlTags } from "../ChipNodeHtmlTags";
import { ExtensionContent, ExtensionGroup } from "./configTypes";

export const InputExtensionConfig = {
  config: {
    name: ChipNodeComponents.InputComponent,

    // group: "block",
    group: ExtensionGroup.Inline,
    content: ExtensionContent.Inline,
    inline: true,
    draggable: true,
    selectable: true, // needed so that we don't delete it when typing things if you click the tool

    atom: true
  },
  attributes: {
    inputId: {
      default: 0
    },
    textValue: {
      default: ""
    },
    name: {
      default: ""
    }
  },
  htmlTag: ChipNodeHtmlTags.InputComponent
};
