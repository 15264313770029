import { isFileOrAudioData, UserInputDictType } from "../../types";
import {
  escapeRegExp,
  get as getValueFromObject,
  isBoolean,
  isString
} from "lodash";
import { getStringsBetweenBraces, validateAndParseJson } from "../helpers";

export const stringPromptFromUserInput = (
  p: string,
  userInput: UserInputDictType
) => {
  const UIKeys = getStringsBetweenBraces(p);

  return UIKeys.reduce((acc, key) => {
    // we need to escape reg expression characters because
    // otherwise parenthesis etc cant be used in inputs
    const escapedKey = escapeRegExp(key);
    const regex = new RegExp(`{{${escapedKey}}}`, "g");
    const splitted = key.split(".");
    const value = userInput[splitted[0]];

    if (isString(value)) {
      const jsonValue = validateAndParseJson(value);
      if (jsonValue) {
        const nestedValue = JSON.stringify(
          getValueFromObject(jsonValue, splitted.slice(1).join("."))
        );
        return acc.replace(regex, nestedValue);
      } else {
        return acc.replace(regex, value);
      }
    }

    if (isBoolean(value)) {
      return acc.replace(regex, value.toString());
    }

    if (isFileOrAudioData(value)) {
      return acc.replace(regex, value.fileName);
    }

    return acc;
  }, p);
};
