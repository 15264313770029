import {
  AutocompleteGroups,
  InputSourceType,
  InputValueType,
  type InputNodeTypes,
  type ToolAutocomplete,
  type WorkflowInput
} from "@toolflow/shared";
import { VARIABLE_NAME } from "../../../../features/pages/workstation/helpers/workspaceConstants";

function formatInputsForAutocomplete(
  inputs: WorkflowInput[],
  inputType: InputNodeTypes,
  id?: string
): ToolAutocomplete[] {
  return inputs
    .filter(
      (i) =>
        i.valueType === InputValueType.String &&
        i.id !== id && // we don't want inputs to be able to reference themselves
        (inputType === "chip"
          ? true
          : i.source !== InputSourceType.ToolOutputPlaceholder)
    )
    .map((i) => {
      return {
        primary: i.name || VARIABLE_NAME,
        id: i.id || "",
        secondary: (i.value as string) || "",
        group: AutocompleteGroups.Asset
      };
    });
}

export default formatInputsForAutocomplete;
