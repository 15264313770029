import { Popover } from "@mui/material";
import {
  ClickableTypes,
  TAddChipConfig
} from "../../../../../../../../utilities/components/textFields/tipTapTextField/types";
import AddChipPaper from "./AddChipPaper";

import styles from "./addChipPopover.module.css";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function AddChipPopover({
  handleAddChip,
  chipsToUse,
  anchorEl,
  setAnchorEl
}: {
  handleAddChip: (type: ClickableTypes, id: string) => void;
  chipsToUse?: TAddChipConfig;
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}) {
  const onClick = (type: ClickableTypes, id: string) => {
    setAnchorEl(null);
    handleAddChip(type, id);
  };

  return (
    <Popover
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
      slotProps={{
        paper: {
          className: cx("h-100-percent w-100-percent", styles["popper-paper"])
        }
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      disableRestoreFocus
      transformOrigin={{
        vertical: 404,
        horizontal: 416
      }}
    >
      <AddChipPaper onClickChip={onClick} chipsToUse={chipsToUse} />
    </Popover>
  );
}

export default AddChipPopover;
