import {
  type ValidatedInput,
  type ToolAutocomplete,
  AutocompleteGroups
} from "@toolflow/shared";

function formatToolbuilderBlockForAutocomplete(
  input: ValidatedInput
): ToolAutocomplete {
  return {
    primary: input.name,
    secondary: "",
    id: input.name || "",
    group: AutocompleteGroups.Inputs
  };
}

export default formatToolbuilderBlockForAutocomplete;
