import { type BlockSettingsBase } from "@toolflow/shared";

export default function useFieldsByTypeHelper({
  settings
}: {
  settings: BlockSettingsBase;
}) {
  function getFieldValue(fieldKey: string, defaultValue: $TSAllowedAny) {
    if (fieldKey) {
      const value = fieldKey
        .split(".")
        .reduce(
          (obj, key) =>
            obj && typeof obj === "object" && key in obj ? obj[key] : undefined,
          settings as $TSAllowedAny
        );
      return value ?? defaultValue;
    }
    throw Error("Field must have a fieldKey");
  }

  return { getFieldValue };
}
