import type { JSONContent } from "@tiptap/core";
import useInputChipsToUse from "../../../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";
import { TipTapTextFieldContainer } from "../../../../../utilities/components/textFields/tipTapTextField/TipTapTextField";

const OutputTipTapTextFieldContainer = ({
  onChange,
  initialValue
}: {
  onChange: (e: string | JSONContent) => void;
  initialValue: string;
}) => {
  const chipsToUse = useInputChipsToUse();
  return (
    <TipTapTextFieldContainer
      onChange={onChange}
      autoFocus={false}
      initialValue={initialValue}
      className="min-w-300px"
      saveWorkspaceType="json"
      disableWhite
      inputType="chip"
      chipsToUse={chipsToUse}
    />
  );
};

export default OutputTipTapTextFieldContainer;
