import React from "react";
import type { CustomToolOutputField } from "@toolflow/shared";
import { ToolbuilderOutputFieldContextProvider } from "./ToolbuilderOutputFieldContext";
import ClickableInputOutput from "../../inputs/inputBuilders/ClickableInputOutput";
import DeleteToolOutputFieldButton from "./DeleteToolOutputFieldButton";
import { outputIconDict } from "../../../../../../../../../utilities/constants/iconDict";

function ToolOutputField({
  toolOutputField
}: {
  toolOutputField: CustomToolOutputField;
}) {
  return (
    <ToolbuilderOutputFieldContextProvider toolOutputField={toolOutputField}>
      <div className="flex">
        <ClickableInputOutput
          icon={outputIconDict[toolOutputField.type]}
          name={toolOutputField.config?.label || toolOutputField.name}
          id={toolOutputField.id}
          SecondaryAction={DeleteToolOutputFieldButton}
          className="m-b-8px"
        />
      </div>
    </ToolbuilderOutputFieldContextProvider>
  );
}

export default ToolOutputField;
