import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSnippetById } from "../../../../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";
import { RootState } from "../../../../../stores/store";
import SnippetChip from "../../../chips/SnippetChip";
import { ClickableTypes } from "../../tipTapTextField/types";
import { ChipNodeHtmlTags } from "@toolflow/shared";
import { useGetSnippetsQuery } from "../../../../../ToolflowAPI/rtkRoutes/snippetsApi";
import { useGetInitialStateQuery } from "../../../../../ToolflowAPI/rtkRoutes/initialStateApi";

const SnippetViewerComponent = (props: NodeViewProps) => {
  const snippet = useSelector((state: RootState) =>
    selectSnippetById(state, props.node.attrs.snippetId)
  );
  const { isLoading: isLoadingSnippets } = useGetSnippetsQuery();
  const { isLoading: isLoadingInitialState } = useGetInitialStateQuery();

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    // added an empty catch block because we get an out of range error in strict mode
    queueMicrotask(() => {
      try {
        const snippetExists = !!snippet;
        if (snippetExists) {
          props.updateAttributes({
            textValue: snippet.snippetValue,
            name: snippet.name
          });
        } else {
          if (!isLoadingSnippets && !isLoadingInitialState) {
            props.deleteNode(); //delete snippet if it doesn't exist, but only if the snippets and initial state aren't loading, because it may still be coming
          }
        }
      } catch {}
    });
  }, [
    snippet?.name,
    snippet?.snippetValue,
    isLoadingSnippets,
    isLoadingInitialState
  ]);

  return (
    <NodeViewWrapper
      className={`inline-flex ${ChipNodeHtmlTags.SnippetChipComponent} dontTriggerBubble`}
    >
      {snippet && (
        <SnippetChip
          text={snippet.name}
          onDelete={props.deleteNode}
          type={ClickableTypes.SNIPPET}
          tabIndex={-1}
        />
      )}
    </NodeViewWrapper>
  );
};

export default SnippetViewerComponent;
