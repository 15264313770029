import type { ToolAutocomplete } from "@toolflow/shared";
import { EAddChipTab } from "../../../../features/pages/workstation/components/chat/chatbox/SendThreadTextField/runButton/helpers/constants";
import React from "react";

export type TAddChipConfig = Partial<
  Record<EAddChipTab, { values: ToolAutocomplete[]; action?: React.ReactNode }>
>;

export type TAddChipTab = Record<
  EAddChipTab,
  {
    entities: ToolAutocomplete[];
    type: ClickableTypes;
    action?: React.ReactNode;
  }
>;

export enum ClickableTypes {
  INPUT = "input",
  SNIPPET = "snippet",
  TOOLBUILDER_INPUT = "toolbuilderInput"
}
