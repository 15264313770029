import {
  CopyableField,
  type UserInputResponseWithHandledError
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import OutputField from "../../outputField/OutputField";

function TestBlockOutput({
  output,
  label,
  type = CopyableField.LargeText
}: {
  output: UserInputResponseWithHandledError | null;
  label: string;
  type?: CopyableField;
}) {
  if (!output) return null;
  return (
    <AccordionWrapper
      elevation={0}
      title="Test Output"
      startsExpanded={!!output}
    >
      {output && (
        <OutputField
          toolOutput={output ? { [label]: output } : null}
          toolOutputField={{
            name: label,
            type
          }}
        />
      )}
    </AccordionWrapper>
  );
}

export default TestBlockOutput;
