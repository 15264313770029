import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React, { useEffect, useMemo } from "react";
import useWorkspaceInputs from "../../../../../features/pages/workstation/hooks/useWorkspaceInputs";
import SnippetChip from "../../../chips/SnippetChip";
import { ClickableTypes } from "../../tipTapTextField/types";
import { ChipNodeHtmlTags } from "@toolflow/shared";

const InputViewerComponent = (props: NodeViewProps) => {
  const inputs = useWorkspaceInputs();

  const input = useMemo(() => {
    return inputs.filter((i) => i.id === props.node.attrs.inputId)[0];
  }, [props.node.attrs.inputId, inputs]);

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    // added an empty catch block because we get an out of range error in strict mode
    queueMicrotask(() => {
      try {
        if (!!input) {
          props.updateAttributes({
            textValue: input.value,
            name: input.name
          });
        } else {
          props.deleteNode(); // when an input gets deleted (like if the tool was deleted), we want to delete the inputs
        }
      } catch {}
    });
  }, [input?.name, input?.value]);

  return (
    <NodeViewWrapper
      className={`inline-flex ${ChipNodeHtmlTags.InputComponent} dontTriggerBubble`}
    >
      {input && (
        <SnippetChip
          text={input.name}
          onDelete={props.deleteNode}
          type={ClickableTypes.INPUT}
          tabIndex={-1}
        />
      )}
    </NodeViewWrapper>
  );
};

export default InputViewerComponent;
