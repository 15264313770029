import Slider, { type SliderOwnProps } from "@mui/material/Slider";
import useUpdateField from "../../../useUpdateField";
import type { SliderFieldProps } from "@toolflow/shared";
import useFieldsByTypeHelper from "../useFieldsByTypeHelper";
import InputLabelWrapper from "./helpers/InputLabelWrapper";

export default function SliderField({ id, field, settings }: SliderFieldProps) {
  const { config, label, subtitle } = field;
  const { fieldKey } = config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });
  const updateField = useUpdateField(id);

  const onChange: SliderOwnProps["onChange"] = (_, newValue) => {
    updateField(newValue as number, fieldKey);
  };

  return (
    <>
      <InputLabelWrapper label={label} subtitle={subtitle}>
        <Slider
          className="nowheel nodrag nopan"
          value={getFieldValue(fieldKey, 0)}
          onChange={onChange}
          marks={[{ value: 50 }]}
        />
      </InputLabelWrapper>
    </>
  );
}
