import {
  CopyableField,
  InputValueType,
  PromptType,
  validateAndParseJson,
  type InputValue,
  type UserInputResponseWithHandledError
} from "@toolflow/shared";
import { isHandledChatStackError } from "../../../../../pages/builder/toolBuilder/typeCheckers";
import isOpenAIImageArray from "../../functions/isOpenAIImageArray";

export const PromptTypeToOutputFieldMap = {
  [PromptType.DallE]: CopyableField.Image,
  [PromptType.ChatGPT]: CopyableField.LargeText,
  [PromptType.Anthropic]: CopyableField.LargeText,
  [PromptType.Structured]: CopyableField.Structured,
  [PromptType.Perplexity]: CopyableField.LargeText
};

export const OutputFieldToValueTypeMap = {
  [CopyableField.Image]: InputValueType.DallE,
  [CopyableField.LargeText]: InputValueType.String,
  [CopyableField.Structured]: InputValueType.Structured
};

/**
 * Returns InputValueType based on CopyableField.
 * @default InputValueType.String
 */
export function getValueTypeFromOutputField(copyableField: CopyableField) {
  return OutputFieldToValueTypeMap[copyableField] || InputValueType.String;
}

/**
 * Returns CopyableField based on PromptType.
 * @default CopyableField.LargeText
 */
export function getOutputFieldValueFromPromptType(promptType: PromptType) {
  return PromptTypeToOutputFieldMap[promptType] || CopyableField.LargeText;
}

export function getValueTypeByInputValue(
  inputValue: InputValue | UserInputResponseWithHandledError
) {
  let value: InputValue = "";
  let valueType: InputValueType = InputValueType.Structured;

  if (typeof inputValue === "string") {
    const jsonValue = validateAndParseJson(inputValue);
    if (jsonValue) {
      value = jsonValue;
    } else {
      value = inputValue;
      valueType = InputValueType.String;
    }
  } else if (isHandledChatStackError(inputValue)) {
    value = inputValue.data.message;
    valueType = InputValueType.String;
  } else if (isOpenAIImageArray(inputValue)) {
    value = inputValue;
    valueType = InputValueType.DallE;
  }

  return { value, valueType };
}
