import mongoose from "mongoose";
import { Image as OpenAIImage } from "openai/resources";
import type { Viewport } from "reactflow";
import { BlockType, DallEOptimizationsType } from "./blocks";
import { LogicItem } from "./blocks/blocks/logicBlockTypes";
import {
  CustomToolInputField,
  ValidatedInputTypes
} from "./inputs/customToolInputFields";
import { AnthropicModel, OpenAiImageModel } from "./LLMModels";
import {
  AvailableFieldsType,
  CustomEdge,
  PopulatedToolVersionResponseFE,
  TBlock,
  TToolVersion,
  ToolVersionDict
} from "./models";
import { LeanFileDocument } from "./models/FileReferenceDocument";
import {
  ExternalToolFE,
  TCategory,
  TUseCase,
  VisibilityTypes
} from "./models/ToolDocument";
import { SanitizedUserType } from "./models/UserDocument";
import { JSONContent } from "@tiptap/core";
import {
  CopyableField,
  CustomToolOutputField,
  OutputType,
  ToolOutputFieldConfig
} from "./outputs";

/* These are types that should be updated */
export type $TSFixMe = any; // eslint-disable-line @typescript-eslint/no-explicit-any

/* Sometimes types should allow for "any", use this type in that situation */
export type $TSAllowedAny = any; // eslint-disable-line @typescript-eslint/no-explicit-any
export type ButtonVariantTypes = "text" | "outlined" | "contained";
export type ButtonSizeTypes = "small" | "medium" | "large";
export type TextFieldSizeTypes = "small" | "medium";

export type UsageType = {
  prompt_tokens: number;
  completion_tokens?: number;
  total_tokens: number;
};

export type RolesType = {
  [key: string]: {
    sessionRole?: string;
    initialPrompt?: string;
  };
};

export type Image = OpenAIImage;
export type DallEOutputType = OpenAIImage[];
export type TAnthropicOutputType = string;

// Define HandledChatStackError
export type HandledChatStackError = {
  type: "error";
  data: { name: string; message: string; stack?: string; status?: number };
};

export type TDeepgramModels =
  | "nova-2"
  | "nova"
  | "enhanced"
  | "base"
  | "whisper-medium";

export type TCrawlerActors = "web-content-crawler";
export type TTimestampByParagraph = "paragraph";
export type TTimestampBySentence = "sentence";
export type TTimestampByEntity = TTimestampByParagraph | TTimestampBySentence;

export enum InputType {
  UserInput = "userInput",
  FixedInput = "fixedInput",
  BlockInput = "blockInput"
}

export type TEmphasizedKeyword = string;

export type JSONSchemaItem =
  | JSONPrimitiveSchema
  | JSONArraySchema
  | JSONObjectSchema
  | JSONEnumSchema;

export interface JSONBaseSchema {
  fieldLabel: string;
  guideline?: string;
}

export interface JSONPrimitiveSchema extends JSONBaseSchema {
  type: "string" | "number" | "integer" | "boolean";
}

export interface JSONArraySchema extends JSONBaseSchema {
  type: "array";
  itemType: JSONSchemaItem;
}

export interface JSONObjectSchema extends JSONBaseSchema {
  type: "object";
  items: JSONSchemaItem[];
}

export interface JSONEnumSchema extends JSONBaseSchema {
  type: "enum";
  enums: string[];
}

export type JSONSchemaItemType = JSONSchemaItem["type"];

export type MapperObject = {
  [key: string]: string;
};

export type TProductionEnvironment = "production";
export type TStagingEnvironment = "staging";

export type TWorkspace = "workspace";
export type TFrequentlyUsed = "frequently-used";
export type TCreatedByUser = "created-by-user";
export type TStarred = "starred";
export type TTool = "tool";
export type TWorkflow = "workflow";
export type TProfileTabType = TWorkspace | TTool | TWorkflow;
export type THomeTabType = TWorkspace | TTool;
export type THomeEntityBlockType = TFrequentlyUsed | TStarred | TCreatedByUser;
export type TCopyableType = TWorkspace | TTool;

export type CopyTypes =
  | "workspace"
  | "workflow"
  | "tool"
  | "profile"
  | "toolVersionResponse";

export type ValidatedInput = {
  name: string;
  type: ValidatedInputTypes;
  options?: string[]; // TODO: Might need to remove this
  config?: ToolOutputFieldConfig;
};

export type TEstimatedAudioLengthInSeconds = 3600;
export type TMaximumAvailableToolRunsForFreeTier = 5;

export type PromptData = {
  prompt: string;
  llmModel: OpenAIModels;
  temperature: number;
  save?: boolean;
  toolInputFields?: CustomToolInputField[];
  toolOutputFields?: CustomToolOutputField[];
  description?: string;
};

export type TTagObject = {
  categories?: TCategory[];
  useCases?: TUseCase[];
  blocks?: TBlocksTagsLabels[];
};

export type UpdateToolDataToSend = {
  main?: string;
  name?: string;
  visibility?: VisibilityTypes;
  about?: string;
  tag?: TTagObject;
};

export type BlockDuration = {
  createdAt: number;
  updatedAt: number;
  duration: string;
};

export type BlockDurationDict = { [key: string]: BlockDuration };

export type RunBlockOptimizations = OptimizationsType | DallEOptimizationsType;

export enum PromptType {
  DallE = BlockType.DallE,
  ChatGPT = BlockType.ChatGPT,
  Anthropic = BlockType.Anthropic,
  Structured = BlockType.Structured,
  Perplexity = BlockType.Perplexity
}

export type ValidateIDResponseFE = {
  status: "success";
};

export type UniqueNameOutput = {
  inputs: ToolOutputOption[];
  blocks: ToolOutputOption[];
};

export type ToolOutputOption = {
  value: string;
  type: CopyableField;
  outputs?: ToolOutputOption[];
} & (ToolOutputOptionConfigs | ToolOutputOptionNoConfigs);

export interface ToolOutputOptionConfigs {
  outputType: OutputType;
  label: string;
  nodeName: string;
  fieldKey: string;
}
export interface ToolOutputOptionNoConfigs {
  outputType?: null;
}

export type OriginalToolState = {
  toolName: string;
  toolAbout: string;
  toolOutputFields: CustomToolOutputField[];
  toolInputFields: CustomToolInputField[];
  blocks: TBlock[];
  edges: CustomEdge[];
  availableFields: AvailableFieldsType;
  toolOutputOptions: UniqueNameOutput;
  visibility: VisibilityTypes;
  tag: TTagObject;
  estimatedCreditCost: number;
};

export type ToolState = {
  toolVersions: ToolVersionDict;
  main: TToolVersion | null;
  creator: SanitizedUserType | null;
  toolId: string;
  toolVersionId: string;
  currentState: OriginalToolState;
  originalState: OriginalToolState;
  toolLoading: boolean;
  userInput: UserInputDictType;
  toolVersionResponse: PopulatedToolVersionResponseFE | null;
};

export type ToolReducerState = {
  tempViewport: Viewport | null;
  showTagsDialog: boolean;
  reducerId: string;
  toolOutput: UserInputDictType | null;
  toolPercentCompleted: number | null;
} & ToolState;

export type UpdateSType =
  | string
  | number
  | boolean
  | MapperObject
  | TWordPairsDict
  | LogicItem[]
  | JSONContent
  | JSONSchemaItem[]
  | TUserKeyWords
  | ExternalToolFE
  | null
  | undefined;

export type TUserKeyWords = string[];
export type TWordPairsDict = { [key: string]: string };
export type UpdateArrayItem = {
  updateValue: UpdateSType;
  dataProperty: string;
  nestedArrayIndex?: number;
  nestedArrayProp?: string;
};

export interface UpdateToolParams {
  main?: string;
  visibility?: VisibilityTypes;
  about?: string;
  name?: string;
  tag?: TTagObject;
}

export type TBlockTagsType =
  | "promptBlockNode"
  | "deepgramBlockNode"
  | "scraperBlockNode"
  | "serpBlockNode"
  | "structuredBlockNode"
  | "perplexityBlockNode";

export type TBlocksTagsKeys =
  | "ChatGPT"
  | "Anthropic"
  | "WebsiteContentCrawler"
  | "DeepgramTranscribe"
  | "Dall-E2"
  | "Dall-E3"
  | "serp";

export type TBlocksTagsLabels =
  | "OpenAI"
  | "Deepgram"
  | "Web Scraper"
  | "Dall-E"
  | "Anthropic"
  | "Serp";

export interface FormState {
  [id: string]: string | boolean | string[] | LeanFileDocument | undefined;
}

// Define the base type
export type UserInputBaseResponse =
  | string
  | DallEOutputType
  | JSON
  | boolean
  | UserInputDictType
  | LeanFileDocument
  | TUserKeyWords
  | TAnthropicOutputType;

// Define UserInputResponseWithHandledError by adding HandledChatStackError to the base type
export type UserInputResponseWithHandledError =
  | UserInputBaseResponse
  | LogicGate
  | HandledChatStackError;

export type UserInputDictType = {
  [key: string]: UserInputResponseWithHandledError;
};

export type UserInputConfig = {
  userId: string;
  userInput: UserInputDictType;
};

export type RowUserInputDictType = UserInputDictType & {
  componentId: string;
  rowLoading: boolean;
  columnIdSelected: string;
};

export type LogicGate = {
  type: "logic";
  passed: boolean;
};

export type OpenAITextModels =
  | Tgpt35Turbo
  | Tgpt4
  | Tgpt4TurboPreview
  | Tgpt4o
  | Tgpt4oAug;

export type DeprecatedOpenAITextModels = Tgpt35Turbo16K;

// TODO: Migrate to using LLM model types from enums in LLMModels file.
export type Tgpt35Turbo = "gpt-3.5-turbo";
export type Tgpt35Turbo16K = "gpt-3.5-turbo-16k";
export type Tgpt4 = "gpt-4";
export type Tgpt4TurboPreview = "gpt-4-turbo-preview";
export type Tgpt4o = "gpt-4o";
export type Tgpt4oAug = "gpt-4o-2024-08-06";

export type OpenAIImageModels =
  | OpenAiImageModel.DALLE2
  | OpenAiImageModel.DALLE3;

export type OpenAIModels = OpenAITextModels | OpenAIImageModels;

export type OptimizationsType = {
  llmModel: OpenAITextModels;
  temperature: number;
};
export type TAnthropicOptimizationsType = {
  llmModel: AnthropicModel;
  temperature: number;
};
export type RequestContextKey = keyof IRequestContextTypes;
export interface IRequestContextTypes {
  [key: `executingBlocks-${string}`]: Set<string>;
  [key: `executedBlocks-${string}`]: Set<string>;
  [key: `intermediateResults-${string}`]: UserInputDictType;
}
export type IRequestContext = Partial<IRequestContextTypes>;

export interface IRunBlockTrackingMetrics {
  toolId: string;
  toolVersionId: string;
  blockId: string;
}

// we mock run block output when running runBlock because
// we don't want to filter them away. We want to test tools
// that might not be in the output
type RunBlockOutput = { name: string; id: string }[];

export type TOutputsForWebsocket = CustomToolOutputField[] | RunBlockOutput;

export type RunPrompt = {
  outputId: string;
  userId: string;
  clientId?: string;
  componentId: string;
};

export type RunPromptResponse = {
  text: string;
  usage: {
    prompt_tokens: number;
    total_tokens: number;
  };
  completion: MessageType | undefined;
  explanation: string;
  outputData: string;
};

export type MessageType = {
  role: "user" | "assistant" | "system";
  content: string;
};

export type PostProcessDictType = {
  emailDict: Record<string, number>;
  phoneDict: Record<string, number>;
};

export type MatchParamsType = {
  userId: mongoose.Types.ObjectId;
  isDeleted: false;
  visibility?: VisibilityTypes;
};

export type RunToolAPIBody = { userInput: UserInputDictType };
export type RunToolBody = RunToolAPIBody & { componentId: string };
export type ExternalApiRunToolBody = RunToolAPIBody & {
  apiConfig: TRunToolAPIConfig;
};

export type TRunToolAPIConfig = {
  callbackUrl?: string;
  sendStubbedResponse?: boolean;
};

export type NoBody = null;

export type AnyBody = unknown;

// we have to have an emptyObjectSymbol
// because Record<string, never> doesnt type check correctly because never bypasses typechecking
declare const emptyObjectSymbol: unique symbol;
export type EmptyObject = { [emptyObjectSymbol]?: never };
export type NoParams = EmptyObject;
export type NoQuery = EmptyObject;

export type ComponentIdStreamMap = Map<string, Set<() => void>>;

// Todo: We might need to improve this.
export type JSONValue = Record<$TSFixMe, $TSFixMe>;
