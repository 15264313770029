import { Button, Grid, Stack } from "@mui/material";
import type { JSONSchemaItem } from "@toolflow/shared";
import ArrayTypeSchemaFields from "./schemaFields/ArrayTypeSchemaFields";
import EnumSchemaFields from "./schemaFields/EnumSchemaFields";
import GuidelineSchemaField from "./schemaFields/GuidelineSchemaField";
import MainSchemaFields from "./schemaFields/MainSchemaFields";
import ObjectTypeSchemaFields from "./schemaFields/ObjectTypeSchemaFields";
import SimpleAccordion from "./SimpleAccordion";
import useJsonSchemaConstructor from "./useJsonSchemaConstructor";

export default function JsonSchemaConstructor({
  id,
  schema
}: {
  id: string;
  schema: JSONSchemaItem[];
}) {
  const { addSchemaItem, removeIndex, updateSchemaItem, updateFieldNameInTOF } =
    useJsonSchemaConstructor({
      id,
      schema
    });

  return (
    <Stack spacing={2} className="m-b-32px">
      {schema.map((schemaItem, index) => {
        return (
          <SimpleAccordion
            key={index}
            onDelete={() => removeIndex(index)}
            header={
              <MainSchemaFields
                schemaItem={schemaItem}
                // updateFieldNameInTOF={updateFieldNameInTOF}
                updateFieldNameInTOF={(p, n) => {
                  if (schemaItem.type === "object") {
                    schemaItem.items.forEach((itm) => {
                      updateFieldNameInTOF?.(
                        `${p}.${itm.fieldLabel}`,
                        `${n}.${itm.fieldLabel}`
                      );
                    });
                  } else {
                    updateFieldNameInTOF(p, n);
                  }
                }}
                onChange={(item) => {
                  updateSchemaItem(index, item);
                }}
              />
            }
          >
            <Grid container spacing={2}>
              {schemaItem.type !== "array" && (
                <Grid item xs={12}>
                  <GuidelineSchemaField
                    schemaItem={schemaItem}
                    onChange={(guideline) => {
                      updateSchemaItem(index, { ...schemaItem, guideline });
                    }}
                  />
                </Grid>
              )}

              {schemaItem.type === "array" && (
                <ArrayTypeSchemaFields
                  schemaItem={schemaItem}
                  onChange={(item) => {
                    updateSchemaItem(index, item);
                  }}
                />
              )}

              {schemaItem.type === "enum" && (
                <EnumSchemaFields
                  enums={schemaItem.enums}
                  onChange={(enums) => {
                    updateSchemaItem(index, { ...schemaItem, enums });
                  }}
                />
              )}

              {schemaItem.type === "object" && (
                <Grid container spacing={2} className="p-t-16px m-l-4px">
                  <ObjectTypeSchemaFields
                    schemaItem={schemaItem}
                    updateFieldNameInTOF={updateFieldNameInTOF}
                    onChange={(items) => {
                      updateSchemaItem(index, { ...schemaItem, items });
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </SimpleAccordion>
        );
      })}

      <Button sx={{ alignSelf: "start" }} onClick={addSchemaItem}>
        + Add new field
      </Button>
    </Stack>
  );
}
