import { getHtmlFromText, type WorkflowInputString } from "@toolflow/shared";
import type { JSONContent } from "@tiptap/core";
import { useAuthStore } from "../../../../../../../auth/hooks/useAuthStore";
import { useInputContext } from "../../../../../../../../utilities/contexts/InputContext";
import { useWorkspaceInputsFieldArrayContext } from "../../../../../contexts/WorkspaceInputsContext";
import useGetWorkspaceInputIndexById from "../../../../../hooks/useGetWorkspaceInputIndexById";
import useChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useChipsToUse";
import useInputChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";

const useAssetTextField = () => {
  const { isAuthenticated } = useAuthStore();
  const chipsToUse = useInputChipsToUse();
  const chipsWithSnippets = useChipsToUse(chipsToUse);

  const { input } = useInputContext<WorkflowInputString>();
  const { update } = useWorkspaceInputsFieldArrayContext();

  const getInputIndex = useGetWorkspaceInputIndexById();
  const onUpdate = (s: string | JSONContent) => {
    const newInput = {
      ...input,
      value: s
    };
    update(getInputIndex(input.id), newInput);
  };

  const initialContent = getHtmlFromText(input.value as string);

  return {
    id: input.id,
    onUpdate,
    disableEditing: !isAuthenticated,
    initialContent,
    chipsToUse: chipsWithSnippets
  };
};

export default useAssetTextField;
