import { useBlockContext } from "../../../BlockContext";
import useGetDrawerNodeId from "../../../store/hooks/useGetDrawerNodeId";
import getBlockPaperLabel from "../../constants/getBlockPaperLabel";
import useSetBlockMessage from "../../hooks/useSetBlockMessage";
import { useBlockIcon } from "../blockIcons/BlockIcon";

const useBlockPaperHeader = () => {
  const { blockData: data, id } = useBlockContext();
  const BlockIcon = useBlockIcon(data);
  const secondaryLabel = getBlockPaperLabel(data.type, data.version);

  const { label } = data;

  useSetBlockMessage();
  const openNodeId = useGetDrawerNodeId();

  return {
    isOpenNode: openNodeId === id,
    label,
    BlockIcon,
    secondaryLabel
  };
};

export default useBlockPaperHeader;
