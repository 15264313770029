import {
  stringPromptFromUserInput,
  type PromptBlockPropsV1
} from "@toolflow/shared";
import useGetFieldsToTestString from "../../../../hooks/useGetFieldsToTestString";
import TestPromptBase from "../helpers/TestPromptBase";
import { useMemo } from "react";
import useFields from "../../../hooks/useFields";

const TestPrompt = ({ data, id }: Omit<PromptBlockPropsV1, "selected">) => {
  const { prompt } = data;
  const fieldsToTest = useGetFieldsToTestString(prompt, id);
  const { userInput, setUserInput, fields } = useFields(fieldsToTest);

  const filledOutPrompt = useMemo(
    () => stringPromptFromUserInput(prompt, userInput),
    [userInput, prompt]
  );

  return (
    <TestPromptBase
      data={data}
      id={id}
      fields={fields}
      userInput={userInput}
      setUserInput={setUserInput}
      filledOutPrompt={filledOutPrompt}
      fieldLabel={"LLM Prompt"}
    />
  );
};

export default TestPrompt;
