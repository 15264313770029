import type { TextInputContainerProps } from "@toolflow/shared";
import useUpdateField from "../../../../useUpdateField";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import { TipTapTextFieldContainer } from "../../../../../../../../utilities/components/textFields/tipTapTextField/TipTapTextField";
import InputLabelWrapper from "../helpers/InputLabelWrapper";
import type { JSONContent } from "@tiptap/core";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";
import { TAddChipConfig } from "../../../../../../../../utilities/components/textFields/tipTapTextField/types";
import { classNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import { useMemo } from "react";

export const textInputContainerExtensionsConfig = {
  disableFormattingExtensions: true,
  disableDefaultEnter: true
};

interface TextInputProps {
  subtitle?: string;
  autofocus?: boolean;
  chipsToUse?: TAddChipConfig;
  id?: string;
  handleTipTapChange: (value: string | JSONContent) => void;
  placeholder?: string;
  initialValue: string;
  label: string;
  className?: string;
}
export function TextInput({
  subtitle,
  autofocus,
  chipsToUse,
  id,
  handleTipTapChange,
  initialValue,
  placeholder,
  label,
  className = ""
}: TextInputProps) {
  return (
    <InputLabelWrapper label={label} subtitle={subtitle}>
      <div className={"flex w-100-percent"}>
        <TipTapTextFieldContainer
          autoFocus={autofocus}
          chipsToUse={chipsToUse}
          id={id}
          extensionsConfig={textInputContainerExtensionsConfig}
          saveWorkspaceType="json"
          defaultExpanded
          initialValue={initialValue}
          onChange={handleTipTapChange}
          disableWhite
          label={placeholder}
          outerClassName="w-100-percent"
          className={classNames("min-w-300px", className)}
        />
      </div>
    </InputLabelWrapper>
  );
}

export default function TextInputContainer({
  id,
  settings,
  field
}: TextInputContainerProps) {
  const updateField = useUpdateField(id);
  const { label, subtitle } = field;
  const { autofocus, placeholder, fieldKey } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  const handleTipTapChange = (value: string | JSONContent) => {
    updateField(value, fieldKey);
  };

  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  const initialValue = useMemo(() => getFieldValue(fieldKey, ""), [fieldKey]);

  return (
    <TextInput
      subtitle={subtitle}
      autofocus={autofocus}
      chipsToUse={chipsToUse}
      handleTipTapChange={handleTipTapChange}
      className="min-h-246px"
      placeholder={placeholder}
      label={label}
      initialValue={initialValue}
    />
  );
}
