import type { PromptInputWrapperProps } from "@toolflow/shared";
import useUpdateField from "../../../useUpdateField";
import useFieldsByTypeHelper from "../useFieldsByTypeHelper";
import PromptEditor from "../../../promptEditor/PromptEditor";
import InputLabelWrapper from "./helpers/InputLabelWrapper";

export default function PromptInputWrapper({
  id,
  settings,
  field
}: PromptInputWrapperProps) {
  const updateField = useUpdateField(id);
  const { label, subtitle } = field;
  const { fieldKey, minRows, maxRows } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  return (
    <InputLabelWrapper label={label} subtitle={subtitle}>
      <PromptEditor
        id={id}
        prompt={getFieldValue(fieldKey, "")}
        handleUpdatePrompt={(newValue) => {
          updateField(newValue, fieldKey);
        }}
        maxRows={maxRows}
        minRows={minRows}
      />
    </InputLabelWrapper>
  );
}
