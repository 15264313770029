import type { UserInputDictType } from "@toolflow/shared";
import { useEffect, useState } from "react";
import arrayToObject from "../functions/arrayToObject";

const useFields = (fields: string[]) => {
  const [userInput, setUserInput] = useState<UserInputDictType>(() =>
    arrayToObject(fields)
  );

  useEffect(() => {
    setUserInput((prevUserInput) => {
      const newUserInput = { ...prevUserInput };

      // Remove fields that are no longer present
      Object.keys(newUserInput).forEach((key) => {
        if (!fields.includes(key)) {
          delete newUserInput[key];
        }
      });

      // Add new fields
      fields.forEach((field) => {
        if (!(field in newUserInput)) {
          newUserInput[field] = "";
        }
      });

      return newUserInput;
    });
  }, [fields]);

  return { userInput, setUserInput, fields };
};

export default useFields;
