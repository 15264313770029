import {
  InputSourceType,
  type TWorkspaceDataToSave,
  type UserInputDictType,
  type WorkflowInput,
  type WorkflowToolInputs
} from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { getValueTypeByInputValue } from "../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";
import { useToolContext } from "../../../tools/contexts/ToolContext";
import { WORKSPACE_INPUTS_FIELD_LABEL } from "../helpers/workspaceConstants";

const useSetToolOutput = ({
  currentInputs,
  userInput,
  componentId,
  tvrId,
  setToolOutputBase
}: {
  currentInputs: React.MutableRefObject<WorkflowInput[]>;
  userInput: UserInputDictType;
  componentId: string;
  tvrId: string;
  setToolOutputBase: (output: UserInputDictType | null) => void;
}) => {
  const { tool } = useToolContext();
  const { creator, _id: toolId } = tool;
  const { setValue } = useFormContext<TWorkspaceDataToSave>();

  const setToolOutput = (output: UserInputDictType | null) => {
    const savedInputs = [...currentInputs.current];
    if (output) {
      // Step 1: Update existing inputs and populate the inputMap with the updates
      savedInputs.map((input, idx) => {
        // if its not the source identifier, return the old version
        // this keeps the order of the inputs static
        if (input.sourceIdentifier !== componentId) {
          return input;
        }

        const outputKey = input.data?.outputId || "";
        const tempValue = output[outputKey];
        const { value, valueType } = getValueTypeByInputValue(tempValue);

        const existingToolCounts = savedInputs.filter((i) => {
          if (!i.data) {
            return false;
          } else {
            return (
              i.data.toolId === toolId && i.sourceIdentifier !== componentId
            );
          }
        }).length;

        // can't use update because dirty has to be false so we
        // don't overload the backend
        // we  queueMicrotask, because the input changes come in rapid succession
        // and sometimes the last input doesn't get set
        queueMicrotask(() => {
          setValue(
            `${WORKSPACE_INPUTS_FIELD_LABEL}.${idx}`,
            {
              ...input,
              loading: true,
              source: InputSourceType.ToolOutput,
              data: {
                toolId,
                tvrId,
                creatorId: creator._id,
                userInput,
                outputId: input.data?.outputId,
                toolCount: existingToolCounts
              },
              value,
              valueType
            } as WorkflowToolInputs,
            { shouldValidate: false, shouldDirty: false, shouldTouch: false }
          );
        });
      });
    }

    // we need this because the tool may be closed, in which case
    // setAttributes will throw an error. But we don't care, because
    // the rest of the tool should work.
    try {
      setToolOutputBase(output);
    } catch {
      console.log("tool deleted");
    }
  };

  return setToolOutput;
};

export default useSetToolOutput;
