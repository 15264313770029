import type { Extension } from "@tiptap/core";
import {
  InputExtensionConfig,
  SearchToolResponseExtensionConfig,
  SnippetExtensionConfig,
  ToolbuilderInputExtensionConfig
} from ".";
import createExtensionFromConfigBackend from "./createExtensionFromConfigBackend";

const extensionsConfig = [
  InputExtensionConfig,
  SnippetExtensionConfig,
  ToolbuilderInputExtensionConfig,
  SearchToolResponseExtensionConfig
];

export const customExtensions: Extension[] = Object.values(
  extensionsConfig
).map((config) => createExtensionFromConfigBackend(config));
