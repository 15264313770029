import { ListItemText, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { type SelectInputProps } from "@mui/material/Select/SelectInput";
import Typography from "@mui/material/Typography";
import {
  type SelectInputWrapperProps,
  type SelectOption
} from "@toolflow/shared";
import { isString } from "lodash";
import useUpdateField from "../../../useUpdateField";
import useFieldsByTypeHelper from "../useFieldsByTypeHelper";
import InputLabelWrapper from "./helpers/InputLabelWrapper";

interface Props {
  id: string;
  value: string;
  fieldKey: string;
  label: string;
  subtitle?: string;
  options: SelectOption[];
  callback?: (value: string) => void;
}

function NewSelectField({
  id,
  value,
  fieldKey,
  label,
  options,
  subtitle,
  callback
}: Props) {
  const updateField = useUpdateField(id);

  const onChange: SelectInputProps["onChange"] = (event) => {
    updateField(event.target.value as string, fieldKey);
    if (callback) {
      callback(event.target.value as string);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabelWrapper label={label} subtitle={subtitle}>
        <Select
          size="small"
          value={value}
          className="nowheel nodrag nopan"
          onChange={onChange}
        >
          {options.map((option, i) => {
            if (isString(option)) {
              return (
                <MenuItem value={option} key={i}>
                  <ListItemText>{option}</ListItemText>
                </MenuItem>
              );
            }

            return (
              <MenuItem value={option.value} key={option.value}>
                <ListItemText>
                  <Stack direction="row" alignItems="center">
                    <Typography>{option.label}&nbsp;</Typography>
                    {option.subText && (
                      <Typography variant="body2" color="text.secondary">
                        {option.subText}
                      </Typography>
                    )}
                  </Stack>
                </ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </InputLabelWrapper>
    </FormControl>
  );
}

export default function NewSelectFieldWrapper({
  field,
  settings,
  id
}: SelectInputWrapperProps) {
  const { fieldKey, options, callback } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  return (
    <NewSelectField
      id={id}
      label={field.label}
      subtitle={field.subtitle}
      options={options}
      value={getFieldValue(fieldKey, 0)}
      fieldKey={fieldKey}
      callback={callback}
    />
  );
}
