import { Box, Divider, Stack } from "@mui/material";
import { type FieldsByTypeProps, FieldType } from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import SelectField from "./fields/selectField/SelectField";
import NewSelectField from "./fields/NewSelectField";
import SliderField from "./fields/SliderField";
import styles from "./fieldsByType.module.css";
import JsonSchemaInputField from "./fields/jsonSchemaInputField/JsonSchemaInputField";
import PromptInputWrapper from "./fields/PromptInputWrapper";
import InputTypeSelectWrapper from "./fields/selectWithInput/InputTypeSelectWrapper";
import React from "react";
import TextInputContainer from "./fields/textInputContainer/TextInputContainer";

const cx = parsedClassNames.bind(styles);

export default function FieldsByType({
  configs,
  settings,
  id
}: FieldsByTypeProps) {
  // Combined object for components and prop getters
  const fieldTypeConfigs: Record<FieldType, React.ElementType> = {
    [FieldType.PromptInput]: PromptInputWrapper,
    [FieldType.PromptInputV2]: TextInputContainer,
    [FieldType.SelectInput]: SelectField,
    [FieldType.CustomSelectInput]: InputTypeSelectWrapper,
    [FieldType.JsonSchemaInput]: JsonSchemaInputField,
    [FieldType.Slider]: SliderField,
    [FieldType.NewSelectInput]: NewSelectField
  };

  return (
    <Stack spacing={2} divider={<Divider />}>
      {configs.fields.map((field, index) => {
        const Component = fieldTypeConfigs[field.type];
        if (!Component) return null;
        return (
          <Box key={index} className={cx("box-margin-inline")}>
            <Component field={field} settings={settings} id={id} />
          </Box>
        );
      })}
    </Stack>
  );
}
