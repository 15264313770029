import { Divider, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { FC } from "react";
import useAddChip from "../runButton/hooks/useAddChip";
import {
  classNames,
  parsedClassNames
} from "../../../../../../../../utilities/functions/parsedClassNames";
import { SearchIcon } from "../../../../../../../../globalTheme/icons/icons";
import styles from "./addChipPaper.module.css";
import { ChipGroup } from "./ChipGroup";
import {
  ClickableTypes,
  TAddChipConfig
} from "../../../../../../../../utilities/components/textFields/tipTapTextField/types";
import { ALL } from "../runButton/helpers/constants";

const cx = parsedClassNames.bind(styles);

interface IAddChipPaperProps {
  className?: string;
  onClickChip: (type: ClickableTypes, id: string) => void;
  chipsToUse?: TAddChipConfig;
}

const AddChipPaper: FC<IAddChipPaperProps> = ({
  className,
  onClickChip,
  chipsToUse = {}
}) => {
  const {
    tabs,
    handleChangeTabIndex,
    selectedTabIndex,
    entitiesObject,
    setFilteredString,
    filteredString
  } = useAddChip({ chipsToUse });

  const getChipsExist = (type: string) => {
    return !!type;
  };

  return (
    <Box
      className={classNames(
        "p-t-10px p-b-20px h-100-percent flex flex-grow-1 flex-column",
        className
      )}
    >
      <Tabs
        value={selectedTabIndex}
        onChange={handleChangeTabIndex}
        className="p-h-20px"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 }
          }
        }}
      >
        {tabs.map((tab) => {
          if (tab.value !== ALL) {
            const { type } = entitiesObject[tab.value] || {};
            const chipsExist = getChipsExist(type);
            if (!chipsExist) return null;
          }
          return (
            <Tab
              key={tab.value}
              value={tab.indexValue}
              label={<span className={`capitalize`}>{tab.label}</span>}
            />
          );
        })}
      </Tabs>
      <Divider />
      <TextField
        size="small"
        margin="normal"
        fullWidth
        autoComplete="off"
        value={filteredString}
        className="p-h-20px"
        autoFocus
        InputProps={{
          startAdornment: <SearchIcon size={20} className="m-r-10px " />
        }}
        onChange={(e) => setFilteredString(e.target.value)}
      />
      <div
        className={cx(
          "scrollable-content h-100-percent w-100-percent flex-grow-1 p-h-20px",
          styles["entity-container"]
        )}
      >
        {tabs.map((tab) => {
          if (tab.value === ALL) return null;
          const { entities, type, action } = entitiesObject[tab.value] || {};
          const chipsExist = getChipsExist(type);
          const hideOnTabsThatArentAll =
            selectedTabIndex !== 0 && selectedTabIndex !== tab.indexValue;
          if (!chipsExist || hideOnTabsThatArentAll) return null;

          return (
            <ChipGroup
              key={tab.value}
              name={tab.label}
              type={type}
              entities={entities}
              onClickChip={onClickChip}
              action={action}
            />
          );
        })}
      </div>
    </Box>
  );
};

export default AddChipPaper;
