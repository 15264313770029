let tabId = 0;

export const ALL = "all";

export type TAddChipTab = {
  label: string;
  value: EAddChipTab | typeof ALL;
  indexValue: number;
};

export enum EAddChipTab {
  ASSETS = "assets",
  SNIPPETS = "snippets",
  NODES = "toolbuilderInputs"
}

export const addChipTabs: TAddChipTab[] = [
  {
    label: "All",
    value: ALL,
    indexValue: tabId++
  },
  {
    label: "Inputs",
    value: EAddChipTab.NODES,
    indexValue: tabId++
  },
  {
    label: "Assets",
    value: EAddChipTab.ASSETS,
    indexValue: tabId++
  },
  {
    label: "Snippets",
    value: EAddChipTab.SNIPPETS,
    indexValue: tabId++
  }
];
