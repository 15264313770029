import React from "react";
import { Typography, Paper } from "@mui/material";
import BlockAlert from "../BlockAlert";
import DeleteNodeButton from "../deleteNodeButton/DeleteNodeButton";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./blockPaperHeader.module.css";
import BlockIconWrapper from "../blockIcons/BlockIcon";
import { toolflowTextSecondaryColor } from "../../../../../../../globalTheme/muiUtils/appTheme";
import useBlockPaperHeader from "./useBlockPaperHeader";

const cx = parsedClassNames.bind(styles);

function BlockPaperHeader() {
  const { isOpenNode, label, BlockIcon, secondaryLabel } =
    useBlockPaperHeader();

  return (
    <Paper
      className={cx(
        {
          "border-on-hover": !isOpenNode,
          "border-on-select": isOpenNode
        },
        "cursor-pointer border-radius-8px bg-color-right-side-content",
        styles["paper-header-container"]
      )}
    >
      <Paper
        className={cx(
          "flex align-i-center justify-space-between p-8px border-radius-6px",
          styles["paper-header"]
        )}
        variant="outlined"
      >
        <div
          className="align-i-center flex w-100-percent"
          style={{ maxWidth: "88%" }}
        >
          {BlockIcon && (
            <BlockIconWrapper
              BlockIcon={
                <BlockIcon color={toolflowTextSecondaryColor} size={20} />
              }
            />
          )}
          <div className="m-l-8px flex flex-column">
            <Typography variant="chipSmall" color="text.secondary">
              {secondaryLabel}
            </Typography>
            <Typography variant="nodeHeader">{label}</Typography>
          </div>
        </div>
        <div className="flex align-i-center">
          <DeleteNodeButton />
        </div>
      </Paper>
      <BlockAlert />
    </Paper>
  );
}

export default BlockPaperHeader;
