import type { Extension } from "@tiptap/core";
import {
  InputExtensionConfig,
  SnippetExtensionConfig,
  ToolbuilderInputExtensionConfig,
  SearchToolResponseExtensionConfig
} from "@toolflow/shared";
import InputViewerComponent from "../../tiptapExtensions/InputViewerComponent";
import SnippetViewerComponent from "../../tiptapExtensions/SnippetViewerComponent";
import createNodeFromConfig from "../../tiptapExtensions/helpers/createNodeFromConfig";
import SearchToolResponseComponent from "../../tiptapExtensions/SearchToolResponseComponent/SearchToolResponseComponent";
import ToolbuilderInputViewerComponent from "../../tiptapExtensions/ToolbuilderInputViewerComponent";

const extensionsConfig = {
  InputViewerExtension: {
    config: InputExtensionConfig,
    component: InputViewerComponent
  },
  SnippetViewerExtension: {
    config: SnippetExtensionConfig,
    component: SnippetViewerComponent
  },
  ToolbuilderInputViewerExtension: {
    config: ToolbuilderInputExtensionConfig,
    component: ToolbuilderInputViewerComponent
  },
  SearchToolResponseExtension: {
    config: SearchToolResponseExtensionConfig,
    component: SearchToolResponseComponent
  }
};

export const customExtensions: Extension[] = Object.values(
  extensionsConfig
).map(({ config, component }) => createNodeFromConfig(config, component));
