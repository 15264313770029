import React, { useEffect, useState } from "react";
import { toolflowTextDisabledColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import SettingsMenuItem from "../../../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import useGetInputHtml from "../../../../../../../../utilities/components/textFields/workspace/hooks/useGetInputHtml";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import useGetCurrentAsset from "../../../../../hooks/assets/useGetCurrentAsset";
import { WordCountIcon } from "../../../../../../../../globalTheme/icons/icons";
import { getPlainTextWithStrippedParagraphs } from "@toolflow/shared";

const WordCountMenuItem = () => {
  const [wordCount, setWordCount] = useState(0);
  const getInputHtml = useGetInputHtml();
  const input = useGetCurrentAsset();

  useEffect(() => {
    if (input) {
      const html = getInputHtml(input);
      const newWordCount = getPlainTextWithStrippedParagraphs(html)
        .trim()
        .split(/\s+/)
        .filter((word) => word.length > 0).length;
      setWordCount(newWordCount);
    }
  }, [input]);

  return (
    <SettingsMenuItem
      text={`${wordCount} words`}
      icon={
        <RemixWrapperIconWithTheme
          size={16}
          Icon={WordCountIcon}
          providedColor={toolflowTextDisabledColor}
        />
      }
      disabled
    />
  );
};

export default WordCountMenuItem;
