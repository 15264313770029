import { BlockSettingsBase, BlockSettingsWithInputType } from "../blocks";

export enum FieldType {
  SelectInput = "select-input",
  CustomSelectInput = "custom-select-input",
  JsonSchemaInput = "json-schema-input",
  PromptInput = "prompt-input",
  PromptInputV2 = "prompt-input-v2",
  Slider = "slider",
  NewSelectInput = "new-select-input"
}

export type SelectOption =
  | string
  | { label: string; value: string; subText?: string };

export interface FieldsByTypePropsBase {
  settings: BlockSettingsBase;
  id: string;
}

export interface FieldsByTypeProps extends FieldsByTypePropsBase {
  configs: FieldConfig;
}

export interface PromptInputWrapperProps extends FieldsByTypePropsBase {
  field: PromptInputFieldType;
}
export interface TextInputContainerProps extends FieldsByTypePropsBase {
  field: PromptInputV2FieldType;
}
export interface SelectInputWrapperProps extends FieldsByTypePropsBase {
  field: SelectInputFieldType;
}

export interface CustomSelectInputWrapperProps extends FieldsByTypePropsBase {
  field: CustomSelectInputFieldType;
  settings: BlockSettingsWithInputType;
}

export interface JsonSchemaInputWrapperProps extends FieldsByTypePropsBase {
  field: JsonSchemaInputFieldType;
}

export interface SliderFieldProps extends FieldsByTypePropsBase {
  field: SliderFieldType;
}

export interface NewSelectInputWrapperProps extends FieldsByTypePropsBase {
  field: NewSelectInputFieldType;
}

export interface BaseInputFieldProps {
  label: string;
  subtitle?: string;
}

export type NewSelectInputFieldConfig = {
  fieldKey: string;
  options: SelectOption[];
  callback?: (value: string) => void;
};

export interface NewSelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.NewSelectInput;
  config: NewSelectInputFieldConfig;
}

export type SelectInputFieldConfig = {
  fieldKey: string;
  options: string[];
  callback?: (value: string) => void;
};

export interface SelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.SelectInput;
  config: SelectInputFieldConfig;
}

export type CustomSelectInputFieldConfig = {
  sourceLabel: string;
  fieldKey: string;
  inputValueType?: "string" | "array";
  userFieldKey?: string;
};

export interface CustomSelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.CustomSelectInput;
  config: CustomSelectInputFieldConfig;
}

export type JsonSchemaInputFieldConfig = {
  subLabel?: string;
};

export interface JsonSchemaInputFieldType extends BaseInputFieldProps {
  type: FieldType.JsonSchemaInput;
  config: JsonSchemaInputFieldConfig;
}

export type PromptInputFieldConfig = {
  fieldKey: string;
  minRows: number;
  maxRows: number;
};

export interface PromptInputFieldType extends BaseInputFieldProps {
  type: FieldType.PromptInput;
  config: PromptInputFieldConfig;
}

export type PromptInputV2FieldConfig = {
  placeholder?: string;
  autofocus?: boolean;
  fieldKey: string;
};

export interface PromptInputV2FieldType extends BaseInputFieldProps {
  type: FieldType.PromptInputV2;
  config: PromptInputV2FieldConfig;
}

type PromptInputs = PromptInputV2FieldType | PromptInputFieldType;

export type SliderFieldConfig = {
  fieldKey: string;
};

export interface SliderFieldType extends BaseInputFieldProps {
  type: FieldType.Slider;
  config: SliderFieldConfig;
}

export type FieldTypeConfigs =
  | CustomSelectInputFieldType
  | JsonSchemaInputFieldType
  | SelectInputFieldType
  | PromptInputs
  | PromptInputV2FieldType
  | SliderFieldType
  | NewSelectInputFieldType;

export interface FieldConfig {
  fields: FieldTypeConfigs[];
}

export interface IFieldInputProps {
  field: FieldTypeConfigs;
  settings: BlockSettingsBase;
  id: string;
}
