import { Content, JSONContent } from "@tiptap/core";
import { LeanSnippetDocument, Snippet, UserInputDictType } from "../../types";
import { ChipNodeComponents } from "../tiptap";

export const getFieldValue = (
  node: JSONContent,
  userInput: UserInputDictType,
  snippets: Snippet[] | LeanSnippetDocument[]
): Content | null => {
  const nodeAttributes = node.attrs;
  if (!nodeAttributes) {
    return null;
  }
  if (
    node.type === ChipNodeComponents.ToolbuilderInputComponent &&
    userInput[nodeAttributes.name]
  ) {
    return userInput[nodeAttributes.name] as Content;
  }
  if (node.type === ChipNodeComponents.SnippetChipComponent) {
    const foundSnippet: Snippet | LeanSnippetDocument | undefined =
      snippets.find(
        (snippet) => snippet._id.toString() === nodeAttributes.snippetId // we need toString to compare object ids
      );
    if (foundSnippet) {
      return foundSnippet.snippetValue;
    }
  }
  return null;
};

export const getPromptFillerBase = (
  cb: (content: JSONContent) => string,
  snippets: Snippet[] | LeanSnippetDocument[]
) => {
  return (prompt: Content, userInput: UserInputDictType): string => {
    if (!prompt) {
      return "";
    }

    if (typeof prompt === "string") {
      return prompt;
    }

    const replaceChipContent = (node: JSONContent): JSONContent => {
      const field = getFieldValue(node, userInput, snippets);
      if (field) {
        if (!field) {
          return {
            type: "text",
            text: " " // No empty strings allowed
          };
        }
        if (typeof field === "string") {
          return {
            type: "text",
            text: field
          };
        }

        return {
          type: "text",
          text: cb(replaceChipContent(field))
        };
      }
      if (Array.isArray(node.content)) {
        return {
          ...node,
          content: node.content.map(replaceChipContent)
        };
      }
      return node;
    };

    const updatedContent = Array.isArray(prompt)
      ? prompt.map(replaceChipContent)
      : replaceChipContent(prompt);

    return cb(updatedContent);
  };
};
