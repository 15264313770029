import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RichTextEditor, type RichTextEditorRef } from "mui-tiptap";
import React from "react";
import { useGetSnippetsQuery } from "../../../../ToolflowAPI/rtkRoutes/snippetsApi";
import WorkspaceMenus from "./components/WorkspaceMenus";
import {
  MuiTipTapContextComponent,
  useMuiTipTapContext
} from "./context/MuiTipTapContext";
import onBoxFocus from "./functions/onBoxFocus";
import useExtension from "./hooks/useExtensions/useExtension";
import useFocusEditorOnMount from "./hooks/useFocusEditorOnMount";
import useGetEditorProps from "./hooks/useGetEditorProps/useGetEditorProps";
import useGetInitialWorkspace from "./hooks/useGetInitialWorkspace";
import useGetValidatedInitialContent from "./hooks/useGetValidatedInitialContent";
import useOnBlur from "./hooks/useOnBlur";
import useResetSlash from "./hooks/useResetSlash";
import useRteRef from "./hooks/useRteRef";
import { WorkspaceProps } from "./helpers/workspaceTypes";
import { mergeRefs } from "react-merge-refs";
import { usePlaceholder } from "./hooks/useExtensions/usePlaceholder";

const MuiTipTap = React.forwardRef<RichTextEditorRef, WorkspacePropsWithRef>(
  (props, ref) => {
    const { muiTipTapProps, shouldRenderBubble } = useMuiTipTapContext();

    const theme = useTheme();

    // we have to pass the ref to the rteRef so that the useHandleKeydown
    // can access the editor
    // if we try to send the editor directly, it will be undefined and wont be reset
    const rteRef = useRteRef();

    const {
      disableWhite,
      enableHover,
      className,
      variant,
      richTextFieldClassName,
      disableAutofocus,
      id,
      disableEditing,
      placeholder,
      extensionsConfig,
      overrideKeydown,
      BoxProps
    } = muiTipTapProps;

    const placeholderText = usePlaceholder(placeholder);

    const extensions = useExtension({
      placeholder: placeholderText,
      ...extensionsConfig
    });

    useGetInitialWorkspace();
    useResetSlash();
    useFocusEditorOnMount({ rteRef });
    useGetSnippetsQuery();

    const validatedInitialContent = useGetValidatedInitialContent({
      rteRef
    });

    const onBlur = useOnBlur();
    const onFocus = onBoxFocus({
      shouldRenderBubble,
      rteRef
    });
    const editorProps = useGetEditorProps({
      rteRef,
      overrideKeydown
    });

    return (
      <Box
        onClick={onFocus}
        className={`pos-relative ${
          disableWhite ? "" : "bg-color-white"
        } flex-grow-1 ${BoxProps?.className ? BoxProps.className : ""}`}
        sx={{
          overflow: "auto",
          ...BoxProps?.sx
        }}
        id={id}
        {...BoxProps}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RichTextEditor
            className={`muiClass h-100-percent ${className} ${
              enableHover ? "updated-tiptap-hover" : "disable-tiptap-hover"
            }`}
            editable={!disableEditing}
            ref={mergeRefs([rteRef, ref])}
            extensions={extensions}
            RichTextFieldProps={{
              variant,
              RichTextContentProps: {
                className: `p-0px ${richTextFieldClassName}`
              }
            }}
            onCreate={() =>
              rteRef.current?.editor?.commands.setContent(
                validatedInitialContent // we are doing this because workflow steps arent showing default values when you go back to that step
              )
            }
            // this used to be onTransaction, might need to be changed
            // i think on blur should be sufficient for most changes though
            onBlur={onBlur}
            parseOptions={{ preserveWhitespace: true }}
            // renderControls={() => renderControls()}
            // this is so that toolmenu enter works appropriately
            editorProps={editorProps}
            autofocus={!disableAutofocus}
          >
            {() => <WorkspaceMenus />}
          </RichTextEditor>
        </ThemeProvider>
      </Box>
    );
  }
);

MuiTipTap.displayName = "MuiTipTap";
// Modify the WorkspaceProps to include the ref
export interface WorkspacePropsWithRef extends WorkspaceProps {
  ref?: RichTextEditorRef;
}

const Workspace = React.forwardRef<RichTextEditorRef, WorkspacePropsWithRef>(
  (props, ref) => {
    return (
      <MuiTipTapContextComponent {...props}>
        <MuiTipTap ref={ref} />
      </MuiTipTapContextComponent>
    );
  }
);

Workspace.displayName = "Workspace";

export default Workspace;
