import type { JSONSchemaItem } from "@toolflow/shared";
import useUpdateField from "../../../../../useUpdateField";
import useFindBlockById from "../../../../../useFindBlockById";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import { structureJsonSchemaInitialState } from "../../../../../context/initialStates/structuredInitialState";

export default function useJsonSchemaConstructor({
  id,
  schema
}: {
  id: string;
  schema: JSONSchemaItem[];
}) {
  const updateField = useUpdateField(id);
  const block = useFindBlockById(id);
  const { dispatch } = useToolbuilderContext();

  function addSchemaItem() {
    const newSchema = [...schema];
    newSchema.push({ ...structureJsonSchemaInitialState });
    updateField(newSchema, "settings.schema");
  }

  function removeIndex(index: number) {
    const newSchema = schema.filter((_, i) => i !== index);
    updateField(newSchema, "settings.schema");
  }

  function updateFieldNameInTOF(prevValue: string, newValue: string) {
    if (block?.data.label) {
      dispatch({
        type: "RENAME_NESTED_SCHEMA_ITEM_FIELD",
        name: block.data.label,
        newValue,
        prevValue
      });
    }
  }

  function updateSchemaItem(index: number, schemaItem: JSONSchemaItem) {
    const newSchema = [...schema];
    newSchema[index] = schemaItem;
    updateField(newSchema, "settings.schema");
  }

  return {
    removeIndex,
    addSchemaItem,
    updateSchemaItem,
    updateFieldNameInTOF
  };
}
