import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { type ToolOutputOption } from "@toolflow/shared";
import { useMemo } from "react";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import ToolbuilderOutputMenuSubheader from "./ToolbuilderOutputMenuSubheader";
import useGetCurrentToolOutputField from "./hooks/useGetCurrentToolOutputField";
import useGetCurrentToolOutputFieldIndex from "./hooks/useGetCurrentToolOutputFieldIndex";

const ChangeToolOutputNameSelect = ({
  disabled,
  index,
  name
}: {
  disabled?: boolean;
  index: number;
  name: string;
}) => {
  const { dispatch, state } = useToolbuilderContext();
  const { toolOutputOptions } = state.currentState;

  const fields = useMemo(() => {
    const blocks = toolOutputOptions.blocks.reduce((acc, cur) => {
      acc.push(cur);
      if (cur.outputs) {
        acc.push(...cur.outputs);
      }
      return acc;
    }, [] as ToolOutputOption[]);

    return { ...toolOutputOptions, blocks };
  }, [toolOutputOptions]);

  const handleToolOutputFieldChangeName = (value: string) => {
    const newField = [...fields.inputs, ...fields.blocks].filter(
      (f) => f.value === value
    )[0];
    dispatch({
      type: "UPDATE_TOOL_OUTPUT_FIELD_NAME",
      index,
      toolOutputField: newField
    });
  };

  return (
    <FormControl fullWidth disabled={disabled}>
      <Typography variant="subtitle2" color="text.secondary">
        Output
      </Typography>
      <Select
        fullWidth
        value={name}
        className="nowheel nodrag nopan"
        onChange={(e) => handleToolOutputFieldChangeName(e.target.value)}
      >
        <ToolbuilderOutputMenuSubheader secondary="Inputs" />
        {fields.inputs.map((field, idx) => (
          <MenuItem value={field.value} key={idx}>
            {field.outputType ? field.label || field.value : field.value}
          </MenuItem>
        ))}
        <ToolbuilderOutputMenuSubheader secondary="Blocks" />
        {fields.blocks.map((field, idx) => (
          <MenuItem value={field.value} key={idx} disableRipple>
            {field.outputType ? field.label || field.value : field.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function ChangeToolOutputNameSelectContainer({
  disabled
}: {
  disabled?: boolean;
}) {
  const index = useGetCurrentToolOutputFieldIndex();
  const toolOutputField = useGetCurrentToolOutputField();
  const name = toolOutputField.config?.nestedOutputKey
    ? `${toolOutputField?.name}.${toolOutputField?.config.nestedOutputKey}`
    : toolOutputField?.name;

  return (
    <ChangeToolOutputNameSelect disabled={disabled} index={index} name={name} />
  );
}

export default ChangeToolOutputNameSelectContainer;
