import { useInputContext } from "../../../../../../../../../utilities/contexts/InputContext";
import useGetAssetId from "../../../../../../hooks/assets/useGetAssetId";
import useSetVisibleAsset from "../../../../../../hooks/useSetVisibleAsset";
import useIsLastAsset from "../../../hooks/useIsLastAsset";

const useAssetListItem = () => {
  const { input } = useInputContext();
  const setVisibleAsset = useSetVisibleAsset();
  const selectedAsset = useGetAssetId();
  const isLastButton = useIsLastAsset(input.id);
  return {
    isLastButton,
    isSelected: selectedAsset === input.id,
    loading: !!input.loading,
    assetText: input.label || input.name,
    assetId: input.id,
    assetType: input.valueType,
    onClick: () => setVisibleAsset(input.id)
  };
};

export default useAssetListItem;
