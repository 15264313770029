import { useState } from "react";
import AddInputDialog from "../../../../../features/pages/builder/toolBuilder/addInput/AddInputDialog";
import getFilteredFields from "../../../../../features/pages/builder/toolBuilder/promptEditor/functions/getFilteredFields";
import useToolInputFieldsAndAvailableFields from "../../../../../features/pages/builder/toolBuilder/useToolInputFieldsAndAvailableFields";
import { EAddChipTab } from "../../../../../features/pages/workstation/components/chat/chatbox/SendThreadTextField/runButton/helpers/constants";
import formatToolbuilderBlockForAutocomplete from "../../../autocomplete/functions/formatToolbuilderBlockForAutocomplete";
import {
  CustomToolInputFieldTypesEnum,
  type CustomToolInputField
} from "@toolflow/shared";
import { Button } from "@mui/material";
import { AddIcon } from "../../../../../globalTheme/icons/icons";

const AddInputButton = () => {
  const [openInputModal, setOpenInputModal] = useState(false);

  const updateFromAdd = (input: CustomToolInputField) => {
    console.log("updateFromAdd", input);
  };

  return (
    <>
      <Button
        variant="text"
        className="bg-color-white"
        size="small"
        startIcon={<AddIcon />}
        onClick={() => setOpenInputModal(true)}
      >
        Create new input
      </Button>
      <AddInputDialog
        openModal={openInputModal}
        setOpenModal={setOpenInputModal}
        callback={updateFromAdd}
        allowedFields={[
          CustomToolInputFieldTypesEnum.TEXTFIELD,
          CustomToolInputFieldTypesEnum.SELECT
        ]}
        initialType={CustomToolInputFieldTypesEnum.TEXTFIELD}
      />
    </>
  );
};

const useToolbuilderAvailableFieldsChipsToUse = (id: string) => {
  const inputs = useToolInputFieldsAndAvailableFields(id);
  const filteredFields = getFilteredFields(inputs);
  const formattedInputs = filteredFields.map((input) =>
    formatToolbuilderBlockForAutocomplete(input)
  );
  return {
    [EAddChipTab.NODES]: {
      values: formattedInputs,
      action: <AddInputButton />
    }
  };
};

export default useToolbuilderAvailableFieldsChipsToUse;
